/*=================left navibar begin=========================================*/

.gw-container.gw-main-container .gw-sidebar.gw-sidebar-fixed,
.gw-container.gw-main-container .gw-sidebar.gw-sidebar-fixed:before { left: auto }

.gw-sidebar {
  width: 195px;background: #ecf0f5 !important;padding-top: 125px;transition: all ease-in 0.3s;
  position: fixed;
  border-width: 0 1px 0 0;
  bottom: 0;
  top: 0;
  left: 0;
}

.gw-sidebar .nano-pane {
  background: rgba(255, 255, 255, 0);
  font-size: 15px;
}

.gw-nav-list {
  margin: 0;
  padding: 0;
  list-style: none
}

.gw-nav-list>li:first-child { border-top: 0; }

.gw-nav-list>li {
  display: block;
  padding: 0;
  margin: 0;
  border: 0;
  position: relative;
  text-decoration: none;
}
 @-webkit-keyframes 
arrow-slide {  0% {
}
 100% {
-webkit-transform:rotate(180deg);
z-index:3
}
}
 @-webkit-keyframes 
arrow-slide1 {  0% {
}
 100% {
-webkit-transform:rotate(0deg);
z-index:3
}
}

/*.gw-nav-list>li.arrow-down:after {
  content: '';
  width: 7px;
  height: 7px;
  position: absolute;
  right: 10px;
  top: 15px;
  border-top: 1px solid #bababa;
  border-left: 1px solid #bababa;
  -webkit-transform: rotate(45deg);
  -webkit-animation: arrow-slide .5s 0s ease both;
}

.gw-nav-list>li.init-arrow-down:after {
  content: '';
  width: 7px;
  height: 7px;
  position: absolute;
  right: 10px;
  top: 15px;
  border-right: 1px solid #bababa;
  border-bottom: 1px solid #bababa;
  -webkit-transform: rotate(45deg);
}

.gw-nav-list>li.arrow-up:after {
  content: '';
  width: 7px;
  height: 7px;
  position: absolute;
  right: 10px;
  top: 15px;
  border-right: 1px solid #bababa;
  border-bottom: 1px solid #bababa;
  -webkit-transform: rotate(45deg);
  -webkit-animation: arrow-slide1 .5s 0s ease both;
}

.gw-nav-list>li.init-arrow-up:after {
  content: '';
  width: 7px;
  height: 7px;
  position: absolute;
  right: 10px;
  top: 15px;
  border-top: 1px solid #bababa;
  border-left: 1px solid #bababa;
  -webkit-transform: rotate(45deg);
}*/

.gw-nav-list>li>a {
  display: flex;align-items: center;
  padding: 7.5px 0!important;
  background: none !important;
  color: #2D2D2D;
  text-shadow: none!important;
  font-size: 14px;
  text-decoration: none;white-space: nowrap;font-family: 'Avenir LT Std 55';font-weight: 400;
}
.gw-nav-list>li.active>a,.gw-nav-list>li>a:hover{color: #0D6856!important;}
.gw-nav-list>li.active>a{font-weight: 500;}
.gw-nav-list>li.active>a i,.gw-nav-list>li>a:hover i{color: #0D6856!important;}
.gw-nav-list>li.active>a span{transition: all ease-in-out .2s;}

.gw-open > a { outline: 0; }

.gw-nav-list>li.gw-open { border-bottom-color: #e5e5e5 }

.gw-nav-list>li.gw-open>a {
  background-color: #fafafa;
  color: #1963aa
}

.gw-nav-list .gw-open>a,
.gw-nav-list .gw-open>a:hover,
.gw-nav-list .gw-open>a:focus { background-color: #fafafa }

.gw-nav .gw-open > a,
.gw-nav .gw-open > a:hover,
.gw-nav .gw-open > a:focus {
  background-color: #eee;
  border-color: #428bca;
}

.gw-nav-list>li>a,
.gw-nav-list .gw-nav-header { margin: 0 }
.gw-nav-list>li.active>a>[class*="icon-"] {
 font-weight: normal
}

.gw-nav-list>li.active>a:hover:before { display: none }
.gw-nav-list li.gw-open>a:after { display: none }

.gw-nav-list>li a>.gw-arrow {
  display: inline-block;
  line-height: normal;
  text-shadow: none;
  font-size: 18px;
  position: absolute;
  right: 12px;
  top: 12px;
  padding: 0;
  /* color: #595C68; */
}
.gw-nav-list>li a>.gw-arrow>i{font-size: 18px;width: auto;}

.gw-nav-list>li a:hover>.gw-arrow,
.gw-nav-list>li.active>a>.gw-arrow,
.gw-nav-list>li.gw-open>a>.gw-arrow {color: #007AE8;}


/* .gw-nav-list>li>a>[class*="icon-"]:first-child {
  display: inline-block;
  vertical-align: middle;
  min-width: 30px;
  text-align: center;
  font-size: 18px;
  font-weight: normal;
  margin-right: 2px
} */


.gw-nav-list>li .gw-submenu {
  font-size: 13px;
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
  padding-left: 45px;
  position: relative;
}

.gw-nav-list>li .gw-submenu>li {
  margin-left: 0;
  position: relative
}

.gw-nav-list>li .gw-submenu>li>a {
  display: block;
  position: relative;
  color: #878787;
  padding: 5px 0;
  margin: 0;
  font-size: 14px;
  text-decoration: none;
}

.gw-nav-list>li .gw-submenu>li>a:focus { text-decoration: none }

.gw-nav-list>li .gw-submenu>li>a:hover,.gw-nav-list>li .gw-submenu>li>a.active{
  color: #0D6856 !important;
}



.gw-nav-list>li .gw-submenu>li>a:hover,.gw-nav-list>li .gw-submenu>li.active>a { color: #6C7174;/*background: #F4F6F8;*/}
.gw-nav-list>li .gw-submenu>li a>[class*="icon-"]:first-child {
  display: none;
  font-size: 12px;
  font-weight: normal;
  width: 18px;
  height: auto;
  line-height: 12px;
  text-align: center;
  position: absolute;
  left: 10px;
  top: 11px;
  z-index: 1;
  background-color: #FFF
}

.gw-nav-list>li .gw-submenu>li.active>a>[class*="icon-"]:first-child,
.gw-nav-list>li .gw-submenu>li:hover>a>[class*="icon-"]:first-child { display: inline-block }

.gw-nav-list>li .gw-submenu>li.active>a>[class*="icon-"]:first-child { color: #c86139 }

.gw-nav-list>li>.gw-submenu>li:first-child>a { border-top: 0px; }

.gw-nav-list li .gw-submenu { overflow: hidden }

.gw-nav-list li.active.gw-open>.gw-submenu>li.active.gw-open>a.dropdown-toggle:after { display: none }

.gw-nav-list li.active>.gw-submenu>li.active>a:after { display: none }

.gw-nav-list li.active.gw-open>.gw-submenu>li.active>a:after { display: block }

.gw-nav-tabs li[class*=" icon-"],
.nav-tabs li[class^="icon-"] {
  width: 1.25em;
  display: inline-block;
  text-align: center
}
/*=================left navibar end=========================================*/



.nano-content,.gw-nav-list,.gw-nav-list li{float: left;width: 100%;}


ul.outer-sub{background: #fff!important;box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.08);position: fixed;
  left: 195px;
  top: 0;
  bottom: 0;
  width: 268px;
  padding-top: 120px!important;padding: 0 20px;display: none;transition: all 0.25s ease-in-out 0s!important;z-index: 1;}
/*.gw-nav-list>li .gw-submenu>li:hover ul.outer-sub{left: 220px;}*/

ul.outer-sub li a{padding: 10px 0!important;float: left;width: 100%;}

ul.outer-sub.active{display:block;}
.outer-sub li{position:relative;float:left;width:100%;}
.outer-sub .gw-submenu{float:left;width:100%;}

.gw-nav-list>li i{transition: all 0.3s ease-in-out 0s!important;font-size: 13px;}
.gw-nav-list>li.arrow-up .fa-sort-down{transform: rotateZ(-180deg);}
.gw-nav-list>li.arrow-up .gw-arrow{top: 11px;}
.outer-sub li.arrow-down .fa-sort-down{transform: rotateZ(0deg)!important;}
.outer-sub.active>li>a>.gw-arrow{line-height: 0;top: 13px;}
.outer-sub li.arrow-down .gw-arrow>i, .outer-sub li .gw-arrow>i{transform: rotateZ(0deg);}
.outer-sub li.arrow-up .gw-arrow>i {transform: rotateZ(-180deg)!important;}