// Fonts
// $font-default: 'Open Sans', sans-serif;
// $font-primary: 'Dosis', sans-serif;
// $font-secondary: 'Lato', sans-serif;

// Colors
$default: #313131;
$primary: #6C7174;
$secondary: #0D6856;

// General Colors

$placeholder: rgba(97, 97, 97, 0.60);
$input-borderClr:#CED4D8;
// $hoverColor1:#0D6856;

$C-btnclr:#7AB648;
$orange:#F84F00;
$orange1: #DC5D21;

$green:#0D6856;
$green1:#339503;
$red:#D81111;
$rose:#BE12D1;
$blue:#2191DC;
