
@import 'variables';

* { margin: 0px; padding: 0px; border: medium none; outline: medium none !important; list-style: outside none none; font-size: 100%; box-sizing: border-box; }
*, *:before, *:after { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box!important; outline: none!important; }
html, body { /*height: 100%;*/ min-height: 100%;font-family: 'Avenir LT Std 55'!important;color:$default;font-size: 14px!important; font-weight: normal; margin: 0; padding: 0;background: #EDF3F3;/*overflow: hidden;*/}
html{min-height: 99.5vh;}
body{height: 100%;}
a { text-decoration: none!important; outline: none!important; }
a img { border: none; }
a, a:hover, a:active, a:focus, .btn, .close { transition: all 0.2s ease-in-out 0s!important; }
.form-control:focus { outline: none !important; box-shadow: none; }
ul{margin: 0!important;}
li { list-style: none; }
img{max-width:100%;}
::selection { background-color: #007BC9; color: #fff; }
button{cursor: pointer;transition: all ease-in-out .2s;}
.btn:focus,.btn:active{box-shadow:none!important;}

.UpdateFocus{
    cursor: pointer;
    background-color: #29bea0;
    border-radius: 10px;
    padding: 5px;
    color: #fff;
}


@font-face {
  font-family: 'Avenir LT Std 35';
  src: url('../fonts/Avenir-LT-Std-35-Light5169.eot');
  src: url('../fonts/Avenir-LT-Std-35-Light5169.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Avenir-LT-Std-35-Light5169.woff2') format('woff2'),
       url('../fonts/Avenir-LT-Std-35-Light5169.woff') format('woff'),
       url('../fonts/Avenir-LT-Std-35-Light5169.ttf')  format('truetype'),
       url('../fonts/Avenir-LT-Std-35-Light5169.svg#Avenir LT Std') format('svg');
}
@font-face {
  font-family: 'Avenir LT Std 45 Book';
  src: url('../fonts/Avenir-LT-Std-45-Book5171.eot');
  src: url('../fonts/Avenir-LT-Std-45-Book5171.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Avenir-LT-Std-45-Book5171.woff2') format('woff2'),
       url('../fonts/Avenir-LT-Std-45-Book5171.woff') format('woff'),
       url('../fonts/Avenir-LT-Std-45-Book5171.ttf')  format('truetype'),
       url('../fonts/Avenir-LT-Std-45-Book5171.svg#Avenir LT Std') format('svg');
}
@font-face {
  font-family: 'Avenir LT Std 65';
  src: url('../fonts/Avenir-LT-Std-65-Medium5175.eot');
  src: url('../fonts/Avenir-LT-Std-65-Medium5175.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Avenir-LT-Std-65-Medium5175.woff2') format('woff2'),
       url('../fonts/Avenir-LT-Std-65-Medium5175.woff') format('woff'),
       url('../fonts/Avenir-LT-Std-65-Medium5175.ttf')  format('truetype'),
       url('../fonts/Avenir-LT-Std-65-Medium5175.svg#Avenir LT Std') format('svg');
}
@font-face {
  font-family: 'Avenir LT Std 55';
  src: url('../fonts/Avenir-LT-Std-55-Roman5173.eot');
  src: url('../fonts/Avenir-LT-Std-55-Roman5173.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Avenir-LT-Std-55-Roman5173.woff2') format('woff2'),
       url('../fonts/Avenir-LT-Std-55-Roman5173.woff') format('woff'),
       url('../fonts/Avenir-LT-Std-55-Roman5173.ttf')  format('truetype'),
       url('../fonts/Avenir-LT-Std-55-Roman5173.svg#Avenir LT Std') format('svg');
}
@font-face {
  font-family: 'Avenir LT Std 85';
  src: url('../fonts/Avenir-LT-Std-85-Heavy5177.eot');
  src: url('../fonts/Avenir-LT-Std-85-Heavy5177.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Avenir-LT-Std-85-Heavy5177.woff2') format('woff2'),
       url('../fonts/Avenir-LT-Std-85-Heavy5177.woff') format('woff'),
       url('../fonts/Avenir-LT-Std-85-Heavy5177.ttf')  format('truetype'),
       url('../fonts/Avenir-LT-Std-85-Heavy5177.svg#Avenir LT Std') format('svg');
}
@font-face {
  font-family: 'Avenir LT Std 95';
  src: url('../fonts/Avenir-LT-Std-95-Black5179-1.eot');
  src: url('../fonts/Avenir-LT-Std-95-Black5179-1.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Avenir-LT-Std-95-Black5179-1.woff2') format('woff2'),
       url('../fonts/Avenir-LT-Std-95-Black5179-1.woff') format('woff'),
       url('../fonts/Avenir-LT-Std-95-Black5179-1.ttf')  format('truetype'),
       url('../fonts/Avenir-LT-Std-95-Black5179-1.svg#Avenir LT Std') format('svg');
}


/*! ----dropdown-transitions------ !*/
.dropdown .dropdown-menu{height:0;overflow:hidden;transition:all 150ms linear;border-radius: 18px;
	margin-top:-20px!important;visibility:hidden;display:block;opacity:0;filter:alpha(opacity=0);box-shadow:0 3px 6px rgba(0,0,0,.16);left:auto;right:0;padding: 0;}
.dropdown .dropdown-menu.show{max-height:350px;opacity:1;height:auto;overflow:auto;filter:alpha(opacity=100);visibility:visible;margin-top:2px!important;display: block;}
.dropdown-menu>li>a{padding:6px 15px;outline:0;border-radius:4px}
.dropdown-menu>li>a:focus{background:0 0}
.dropdown-menu>li>a:hover,.dropdown-item.active,.dropdown-menu>li>a:hover{background:#f8f9fa;color: #212529;}
.dropdown-menu>li>a span{transition:all .3s ease-out}

.dropdown .dropdown-menu{left: auto!important;right: 0!important;}
.dropdown-menu {min-width: 202px!important;border:none;border-radius: 4px;box-shadow: 0px 3px 6px rgba(0,0,0,0.10);background: #fff;z-index: 9991;}
.dropdown-menu>a{border-radius: 4px;font-size: 13px;font-family: 'Avenir LT Std 55';padding: 6px 15px}
.dropdown-item.active,.dropdown-menu>a:hover,.dropdown-item:active {background: #f8f9fa;color: #212529;}

/*.dropdown-menu>li>a:hover span{color:#fff}*/
.bootstrap-select .dropdown-menu{opacity:0;visibility:hidden;min-width:196px!important;-webkit-transform-origin:top;transform-origin:top;display:block;transition:all .2s linear;-webkit-animation-fill-mode:forwards;animation-fill-mode:forwards;transition:all .2s linear;
    z-index:99;right:0;left:auto;box-shadow:none;padding: 15px;border-radius: 3px;box-shadow:0px 3px 6px rgba(0,0,0,0.10);}
.bootstrap-select.show .dropdown-menu{-webkit-transform:scale(1,1);transform:scale(1,1);opacity:1;visibility:visible}
.bootstrap-select>.dropdown-menu{border:1px solid #E6EAEE!important;}
.bootstrap-select .dropdown-toggle:focus{outline:0!important}
.bootstrap-select .btn{ padding: 8px 15px;border-radius: 3px;color:#4F4F4F;background:transparent;height: 42px;border: 1px solid #CED4D8;border-radius: 8px;}
.bootstrap-select .btn:focus{color:#4F4F4F;background:#fff;}
.dropdown-toggle::after{display: none!important;}

.btn-default.active.focus,.btn-default.active:focus,.btn-default.active:hover,.btn-default:active.focus,.btn-default:active:focus,.btn-default:active:hover,.open>.dropdown-toggle.btn-default.focus,.open>.dropdown-toggle.btn-default:focus,.open>.dropdown-toggle.btn-default:hover{background:#e8e7e7}
.btn-group.show .dropdown-toggle{box-shadow:none}
.btn-default.active,.btn-default:active,.show>.dropdown-toggle.btn-default{background:0 0}
.bootstrap-select .bs-caret .fa-angle-down:before{content:"\f0d7";color:#757575}
.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn){min-width:220px;width: 100%;}
.btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active, .show>.btn-light.dropdown-toggle{background: none;color: #2D2D2D;}

/*.bootstrap-select .dropdown-toggle::after{content: '';background:#1ec0ca;width: 22px;height: 22px;border-radius:50%;border:none;position: relative;top: 5px;}*/
.bootstrap-select .filter-option-inner{position: relative;font-size: 14px;font-family: 'Avenir LT Std 55';padding-right: 10px;width: 100%;
  .fa{font-size: 9px;}
}
.bootstrap-select .filter-option-inner:before{content: '';position: absolute;right: -6px;top: 0;bottom:0;margin:auto;z-index: 1;background: url(../images/downarw.svg) no-repeat center;width:11px;height:8px; }
.bootstrap-select .dropdown-menu.inner {border: none!important;}
.bootstrap-select .dropdown-toggle .filter-option{overflow: visible;display: flex;align-items: center;}
.bootstrap-select .dropdown-menu>li>a{font-size: 13px;font-family: 'Avenir LT Std 55';}
.bootstrap-select .dropdown-menu.inner{min-width: auto!important;}

/*! ----dropdown-transitions------ !*/

/*! ---breadcrumb---- !*/
.breadcrumb-bg>nav{float:left}
.breadcrumb{font-size:13px;background:0 0;padding:0}
.breadcrumb>a>i{font-size:21px}
.breadcrumb>li{float:left}
.breadcrumb>li+li:before{padding:0 10px}
.breadcrumb>a,.breadcrumb>li>a{color:#969a9d}
.breadcrumb>.active,.breadcrumb>a:hover,.breadcrumb>li>a:hover{color:#484848}
.breadcrumb .back-arw{padding:2px 0 0;margin-right:10px;display:inline-block;line-height:0;position:relative;top:-1px;float:left}
.breadcrumb>li:last-child{text-overflow:ellipsis;overflow:hidden;white-space:nowrap;max-width:200px}
/*! ---breadcrumb---- !*/


/*! --------button click styles-------- !*/
.btn{outline:0!important}
.btn-outline-secondary{color: #707070;}
.btn.active,.btn:active{box-shadow:none}
.btn:foucs{box-shadow:none!important}
.button{position:relative;display:inline-block;cursor:pointer;transition:all .4s ease-in}
.button span[class^=fa]{position:relative}

/*! --------button click styles-------- !*/

/*! ---placeholder and form-control--- !*/
input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button{-webkit-appearance:none;-moz-appearance:none;appearance:none;margin:0}
input[type=number]{-moz-appearance:textfield}
.form-control{background-color: #fff;border:1px solid $input-borderClr!important;color: #4F4F4F;font-size: 14px;box-shadow: none;width:100%;transition:all .3s ease-out;
  border-radius: 7px;height: 42px;padding: 8px 15px!important;text-align: left;text-overflow: ellipsis}
.form-control:focus{background-color: #fff;color: #4F4F4F;border-color: $input-borderClr;}

.form-control::-webkit-input-placeholder{color:#4F4F4F;font-size:14px;font-weight: 400;font-family: 'Avenir LT Std 55';}
.form-control::-moz-placeholder{color:#4F4F4F;font-size:14px;font-weight: 400;font-family: 'Avenir LT Std 55';}
.form-control:-ms-input-placeholder{color:#4F4F4F;font-size:14px;font-weight: 400;font-family: 'Avenir LT Std 55';}
.form-control:-moz-placeholder{color:#4F4F4F;font-size:14px;font-weight: 400;font-family: 'Avenir LT Std 55';}
.form-control:focus::-webkit-input-placeholder{border:none;color: #4F4F4F;font-family: 'Avenir LT Std 55';}
textarea.form-control{height: 100px;resize: none;}


.form-group{margin-bottom: 25px;}
.form-group .error,.error_span{margin: 5px 0 0;color: $red;}
.form-group label{color: $default;font-size: 14px;margin-bottom: 10px;font-family: 'Avenir LT Std 65';
  display:flex;justify-content: space-between;align-items: center;}
.form-group label a{font-size: 13px;color: $red;}

/*! ---placeholder and form-control--- !*/


.common-btn, .cancelBtn, .cancelBtn1 {
  background: $C-btnclr;
  border-radius: 6px;
  color: #fff;
  font-size: 14px; /*min-width: 110px;*/
  padding: 5px 15px;
  height: 42px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  position: relative;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    color: #fff;
  }

  &.bg-green1 {
    background: #19967D;
  }

  &.bg-Red {
    background: #D94F4F;
  }

  &.bg-Blue {
    background: #2596be;
  }
}
.cancelBtn{background: #B2B2B3;}
.cancelBtn1{background: #D6E4E3;color: $secondary;
  &:hover{color: $secondary;}
}
.restBtn{font-size: 11px;color: $green;background:rgba(13, 104, 86, .09);width: 45px;height: 30px;font-family: 'Avenir LT Std 65';
  display: inline-flex;align-items: center;justify-content: center;border-radius: 5px;
  &:hover{color: $green;}
}
.exportCSVBtn{font-size: 11px;color: $green;background:rgba(255, 255, 255, .59);font-family: 'Avenir LT Std 65';white-space: nowrap;
  display: inline-flex;align-items: center;justify-content: center;border-radius: 5px;height: 30px;padding: 2px 10px;
  img{margin-left: 5px;}
  &:hover{color: $green;}
  &.bgGreen{background: rgba(13, 104, 86, .1);}
}

.viewTherapy{color: $green;background:rgba(13, 104, 86, .09);width: auto;height: 25px;font-family: 'Avenir LT Std 65';
  display: inline-flex;align-items: center;justify-content: center;font-size: 11px;cursor: pointer;border-radius: 5px;white-space:nowrap;padding:2px 8px;
  &:hover{color: $green;}
}

// ------- toggle button and check box -------

.radioBg{display: flex;}
.radiobtn{position: relative;display: inline-flex;align-items: center;}
.checkbox{width: 100%;}
.radiobtn + .radiobtn{margin-left: 10px;}
.radiobtn label,.checkbox label{margin:0;cursor: pointer;padding-left: 24px;white-space: nowrap;}
.radiobtn label:after, .radiobtn label:before,.checkbox label:after, .checkbox label:before {
  content: "";
  position: absolute;
  left: 0;
  top: -1px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fff;border: 1px solid #C2CCD8;
}
.checkbox label:after, .checkbox label:before{border-radius: 4px;
  background: #eee;
  border-color: #E0E2E6;
}
.radiobtn label:before {
  background: transparent;
  -webkit-transition: 0.1s width cubic-bezier(0.075, 0.82, 0.165, 1) 0s, 0.3s height cubic-bezier(0.075, 0.82, 0.165, 2) 0.1s;
  transition: 0.1s width cubic-bezier(0.075, 0.82, 0.165, 1) 0s, 0.3s height cubic-bezier(0.075, 0.82, 0.165, 2) 0.1s;
  z-index: 2;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 8px;
  background-position: center;
  width: 0;
  height: 0;
  background-image: url(../images/blue-dot.svg);border: none;
}
.checkbox label:before{background-image: url(../images/tickWhite.svg);background-size: 9px;}
.radiobtn input[type="radio"], .radiobtn input[type="checkbox"] {
  display: none;
  position: absolute;
  width: 100%;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
// .radiobtn input[type="radio"]:checked + label,.radiobtn input[type="checkbox"]:checked + label {
//   background: #fff;
//   border-color: #fcae2c;
// }
.radiobtn input[type="radio"]:checked + label:after,.radiobtn input[type="checkbox"]:checked + label:after{background: #fff;border-color:#C2CCD8;}
.radiobtn.checkbox input[type="checkbox"]:checked + label:after {
  background: #7AB648;
  border-color: #7AB648;
}

.radiobtn input[type="radio"]:checked + label:before, .radiobtn input[type="checkbox"]:checked + label:before  {
  width: 16px;
  height: 16px;
}

td .radioBg{height: 15px;
  label{white-space: nowrap;}
}


@-webkit-keyframes blink {
  0% {
    background-color: #fdcb77;
  }
  10% {
    background-color: #fdcb77;
  }
  11% {
    background-color: #fdd591;
  }
  29% {
    background-color: #fdd591;
  }
  30% {
    background-color: #fdcb77;
  }
  50% {
    background-color: #fdd591;
  }
  45% {
    background-color: #fdcb77;
  }
  50% {
    background-color: #fdd591;
  }
  100% {
    background-color: #fdcb77;
  }
}

@keyframes blink {
  0% {
    background-color: #fdcb77;
  }
  10% {
    background-color: #fdcb77;
  }
  11% {
    background-color: #fdd591;
  }
  29% {
    background-color: #fdd591;
  }
  30% {
    background-color: #fdcb77;
  }
  50% {
    background-color: #fdd591;
  }
  45% {
    background-color: #fdcb77;
  }
  50% {
    background-color: #fdd591;
  }
  100% {
    background-color: #fdcb77;
  }
}

.checkBox-group{margin-bottom: 0!important;
  .radioBg{margin-bottom: 20px;}
}


.switchBg{display: flex;align-items: center;}
.switchBg .switch{height: auto;margin: 0;}
.switch {
  position: relative;
  display: inline-block;
  width: 30px;margin: 0;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;top:0;margin: auto;
  background-color: #CFCFCF;
  -webkit-transition: .4s;
  transition: .4s;height: 15px;width: 30px;
}
.switch .slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 0;
  bottom: 0px;top: 0;margin: auto;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border: 1px solid #606060;
}
.switch input:checked + .slider {
  background-color: #29BEA0;
}
.switch input:focus + .slider {
  box-shadow: 0 0 1px rgba(41, 190, 160, .6);
}
.switch input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
  border-color: #29BEA0;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}

.switchBg{
  .switchLables{position: relative;top: -1px;height:16px;margin-left:5px;white-space: nowrap;
    span{font-size: 14px;color: #4F4F4F;transition: all ease-in-out .3s;
      position:absolute;left:0;top:0;
      &.active{opacity: 0;}
    }
  }
  &.active{
    .switchLables span{
      &.active{opacity: 1;}
      &.inactive{opacity: 0;}
    }
  }
}


// ------- toggle button and check box -------


.modal-content{border-radius: 24px;border: none;}
.modal-body{padding: 20px;}

span{position: relative;}
p{font-size: 14px;margin: 0;font-family: 'Avenir LT Std 55';line-height: 23px;}
p + p{margin-top: 20px;}

.mtb-20{margin: 20px 0!important;}
.mt-20{margin-top: 20px!important;}
.mt-40{margin-top: 40px!important;}
.height100{height: 100%;}
@media(min-width:1200px){
  .mt-50{margin-top: 50px!important;}
}




.moreBg{position: absolute;right: 6px;top: -15px;}
.more{float:right;position: relative;width: 26px;height: 26px;display: flex;align-items: center;justify-content: center;}
.more .dropdown-menu{transform: translate3d(0px, 21px, 0px)!important;min-width: 120px!important;padding: 0;border-radius: 8px;}
.more .dropdown-menu.show{padding: 10px;}
// .more .dropdown.show{box-shadow: 0px 1px 2px rgba(0,0,0,.16);border: 1px solid #f7f6fb!important;border-radius: 4px;padding: 10px;}
.more a img{width: 2px;height: 12px;}
.more a img.more-hr{width: 12px;height: 2px;}
// .tp-inRght .dropdown .dropdown-menu{transform: translate3d(0px, 28px, 0px)!important;}

.more>a{display: flex;width: 100%;height: 100%;align-items: center;justify-content: center;border: 1px solid transparent;}
.more a.show{border: 1px solid #d9d9d9!important;box-shadow: 0 3px 6px rgba(0,0,0,.1);border-radius: 3px;}


.navbar-toggler{height: 30px;padding:0;
  .navbar-toggler-icon{width: 35px;
    display: inline-block;
    height: 4px;
    position: relative;border-radius:5px;
    &:before,&:after{width: 35px;
      transition: all ease-in-out 0.3s;
      height: 4px;
      background: #0D6856;
      content: "";
      position: absolute;
      left: 0;
      display: inline-block;border-radius:5px;float: left;top: -13px;
      margin-top: 10px;transform: rotate(-45deg);}
      &:after{transform: rotate(45deg);}
  }
  &.collapsed{
    .navbar-toggler-icon{
      background: #0D6856;
      &:after{top: -21px;transform: rotate(0);}
      &:before{transform: rotate(0);top: 0;}
    }
  }
  &:focus{box-shadow: none;}
}


.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn){min-width: 100px;}


// --------- Table style --------

.tableBg{margin: 0 0 10px;}
.table{margin:0;}
.table * .fa{font: normal normal normal 14px/1 FontAwesome!important;}
.table thead th{font-size: 12px!important;font-weight: normal;color: #6C7174;letter-spacing: 1px!important;padding:.5rem 0.75em!important;
  border-top: none;text-transform: uppercase;white-space: nowrap;font-family: 'Avenir LT Std 65';
  i{color: #B5B5B5;font-size: 7px;}
}
.table thead th,.table td,.table>:not(:last-child)>:last-child>*{border-color: #E6EBEF;vertical-align: middle;}
.table td{border: none !important ;font-size: 14px;}
.table tr{height: 40px !important;}
.table tbody tr:hover{background-color: #EDF3F3;}
.table td{padding:.75em!important;
  .status{position: relative;display:flex;align-items:baseline;white-space:nowrap;line-height:normal;
    &:before{content: '';width: 9px;height: 9px;border-radius: 50%;background: #7AB648;margin-right: 6px;}
    &.New:before,&.paid:before{background: #7AB648;}
    &.Draft:before{background: #DC5D21;}
    &.In-Transit:before{background: #CBDB2A;}
    &.Recieved:before{background: #44ACE2;}
    &.waiting:before{background: #E12424;}
    &.Un-paid:before{background: #D81111;}
  }
  .mbl,.date,.name{white-space: nowrap;}
}
.table .action{width: 80px;text-align: center;}
th.sn,td.sn{width: 60px;text-align: center;}
.mw-50{max-width: 50px;}

.nameOverflow{display: inline-block;max-width: 240px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
td .moreBg{position: inherit;}

td .more .dropdown-menu{transform: translate3d(0px, 25px, 0px)!important;min-width: 120px!important;}
td span.date{display: inline-block;white-space: nowrap;}
td .NoData{display: inline-block;width: 32px;height: 1px;background:#5B6870;}
td .colorTxt{color: #434CFC;padding: 0!important;}
td .colorTxt.red{color: #FF3A3A;}
td .colorTxt span{color: #5B6870;}
// td .Status{position: relative;padding-left: 12px;white-space: nowrap;}
// td .Status:before{content: '';display: inline-block;width: 6px;height: 6px;border-radius: 50%;position: absolute;top: 0;bottom: 0;left:0;margin: auto;}
// td .Status.orange:before{background: #FF9B4D;}
// td .Status.green:before{background: #47B200;}
// td .Status.red:before{background: #f00;}

td .Role-usr{display: inline-flex;align-items: center;color: #0D6856;
  img{margin-left: 5px;}
}


.status{position: relative;display:flex;align-items:center;
  &:before{content: '';width: 9px;height: 9px;border-radius: 50%;background: #7AB648;margin-right: 6px;}
  &.pending{background: $orange;}
}

// --------- Table style --------


.topGrdnt{width: 100%;display: flex;}

* {
  scrollbar-width: thin;
}
*::-webkit-scrollbar {
  width: 6px;
  height: 7px;
}
*::-webkit-scrollbar-track {
  background: #EDF3F3;
}
*::-webkit-scrollbar-thumb {
  background-color: #0D6856;
  border-radius: 30px;
}

.wrapper{/*float: left;min-height: calc(100% - 5px);padding: 20px;height:100%;*/width: 100%;
  transition:all .3s ease-in-out;/*overflow: auto;*/
  // @media(max-width:767px){padding: 10px 15px 0;}
}
.wrapper .side-bg{max-width: 220px;flex:0 0 220px;height: 100%;padding: 0;transition: all .3s ease-in-out;
	*::-webkit-scrollbar-track {
	  background: #fff;
	}
}
.wrapper .sidebar{width:100%;height:calc(100% - 40px);max-height:597px;max-width: 220px;background: #fff;box-shadow:0 1px 2px rgba(0,0,0,.16);position: fixed;transition: all .3s ease-in-out;z-index: 99;border-radius: 18px;}
.wrapper .content{transition: all .3s ease-in-out;padding: 20px 20px 0;overflow: auto;
  @media(max-width:991px){margin-top: 95px;}
  @media(max-width:767px){padding: 20px 10px 15px;}
}
body.active .side-bg,body.active .sidebar{max-width: 80px;flex:0 0 80px;}
/*body.active .sidebar{padding-top: 132px;}
body.active .wrapper{margin: 0;}*/


.headerSide{padding: 25px 25px 0;padding-right: 0;border-bottom: 1px solid #E9E9E9;
	.logoBg{width: 100%;padding: 30px 0;display: flex;height: 99px;position: relative;
		img{transition:all ease-in-out .3s;position: absolute;top: 0;bottom: 0;margin: auto;}
		.toggleView{opacity: 0}
	}
}
body.active .headerSide .logoBg{
	.toggleView{opacity: 1;}
	.toggleHide{opacity: 0;}
}


.sidebar-toggle {width: 17px;float: left;}
.sidebar-toggle .bar {background: #292929;height: 2px;margin-bottom: 3px;transition: all .3s ease-in-out;float: left;}
.sidebar-toggle:hover .bar{background:#434CFC;}
.sidebar-toggle .bar1, .sidebar-toggle .bar3 {width: 13px;}
.sidebar-toggle .bar2 {width: 17px;}

.sidebar-menu{float: left; width: 100%;padding: 35px 25px 25px;padding-right: 0;height: calc(100% - 145px);overflow: auto;}

body.active .bar {background: #434CFC;}
body.active .sidebar-toggle .bar1,body.active .sidebar-toggle .bar3{float: right;}
body.active .gw-nav-list>li>a span,body.active .gw-nav-list>li>a b,body.active .gw-submenu{display: none!important;}

.nano-content,.gw-nav-list,.gw-nav-list li{float: left;width: 100%;}
ul.outer-sub>li>a{padding: 10px 0!important;float: left;width: 100%;color: #646565!important;border-bottom: 1px solid #D9D9D9!important;}
ul.outer-sub>li>a:hover,ul.outer-sub>li.active>a,ul.outer-sub>li>a:hover{color: #007AE8 !important;}
ul.outer-sub>li .gw-submenu>li>a{color: #878787;padding: 8px!important;}
ul.outer-sub.active{display:block;}
.outer-sub li{position:relative;float:left;width:100%;}
.outer-sub .gw-submenu{float:left;width:100%;}
.gw-nav-list>li i{transition: all 0.3s ease-in-out 0s!important;font-size: 18px;min-width:34px;width: 34px;height:34px;display: inline-flex;align-items:center;justify-content:center;margin-right: 10px;background: transparent;border-radius: 8px;}
.gw-nav-list>li.arrow-up .gw-arrow>i{transform: rotateZ(-180deg);}
.outer-sub li.arrow-down .gw-arrow>i{transform: rotateZ(0deg)!important;}
.gw-nav-list>li>a:hover .icon-Catalogue:before,.gw-nav-list>li>a:hover .icon-Manage-users:before,.gw-nav-list>li>a:hover .icon-Analytics:before,
.gw-nav-list>li.active>a .icon-Catalogue:before,.gw-nav-list>li.active>a .icon-Manage-users:before,.gw-nav-list>li.active>a .icon-Analytics:before{color: #007AE8 !important;}
.gw-nav-list>li.active>a i, .gw-nav-list>li>a:hover i{background: rgba(13, 104, 86, .10);}

.red{color: $red!important;}
.orange{color: $orange!important;}
.green{color: $green!important;}
.green1{color: $green1!important;}
.rose{color: $rose!important;}

.dskNone{display: none;}

@media(max-width:1024px){
  body{
    .wrapper{
      .side-bg,.sidebar{max-width: 70px;flex: 0 0 70px;}
    }
    .headerSide{padding: 20px;
      .logoBg {
        .toggleView{opacity: 1;}
        .toggleHide{opacity: 0;}
      }
    }
    .sidebar-menu{overflow: hidden;padding: 20px;height:calc(100% - 165px);transition:all ease-in-out .3s;
      #gw-sidebar{overflow: hidden;}
    }
    &.active{
      .sidebar{max-width: 240px;}
      .sidebar-menu{overflow: auto;}
      .gw-nav-list>li>a span,.gw-nav-list>li>a b,.gw-submenu{display: inline-block!important;}
      .headerSide .logoBg {
        .toggleView{opacity:0;}
        .toggleHide{opacity: 1;}
      }
    }
  }
}

@media(max-width:767px){
  body{
    .wrapper{
      .side-bg,.sidebar{max-width: 0;}
      .sidebar-toggle{position: relative;z-index: 1;
        .mblNone{display: none;}
        .dskNone{display: inline-flex;}
        &:before {
          content: '';
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: #0D6856;
          position: absolute;
          z-index: -1;
          top: -8px;
          left: -14px;
          box-shadow: 0 2px 6px rgb(0 0 0 / 36%);
        }
      }
      .sidebar-menu{padding: 0;}
    }
    &.active{
      .sidebar{max-width: 240px;z-index: 100;
        .sidebar-toggle{
          .mblNone{display: inline-flex;}
          .dskNone{display: none;}
          &:before{background: transparent;box-shadow: none;}
        }
      }
      .wrapper .sidebar-menu{padding: 20px;}
    }
  }
}


.content-wrapper{margin-top: 15px;
  // @media(max-width:991px){margin-top: 95px;}
}


.content .row{margin: 0 -10px;}
.content .row [class*="col"]{padding: 0 10px;}

.box{height:100%;background: #fff;box-shadow: 0px 1px 2px rgba(0,0,0,.16);padding: 25px;border-radius: 3px;border-radius: 18px;
  .boxHead{display: flex;align-items: center;justify-content: space-between;
    h4{font-size: 17px;color: $default;margin: 0;}
    .allBtn{min-width: 80px;padding: 5px 15px;display: inline-flex;align-items: center;justify-content: center;
      background: rgba(13, 104, 86, .09);border-radius: 5px;color: $secondary;
      img{margin-left: 8px;}
    }
  }
  @media(max-width:1200px){
    padding: 20px;
  }
}

.pageFiltrBg{display: flex;justify-content: space-between;flex-wrap: wrap;margin-bottom: 20px;position: relative;
  &.show:before{content: '';background:rgba(0,0,0,.22);
    width: 100%;height: 100%;top: 0;left: 0;position: fixed;z-index: 99;}
  .rghtSec{display: inline-flex;align-items:center;
    >li{margin-right: 25px;
      @media(max-width:1400px){margin-right: 18px;}
    }
  }
  .lftSec{display: inline-flex;align-items:center;
    .switchBg{margin-left: 20px;width: 155px;}
  }
  .searchBg{min-width: 300px;
    &.search-borderd{max-width: 300px;}
    .mat-mdc-icon-button{height: 42px;color: #616161;
      .mat-icon{font-size: 18px;}
    }
  }
  @media(max-width:991px){
    flex-direction: column;
    .rghtSec{justify-content: flex-end;margin-top: 10px;flex-wrap: wrap;
      > li{margin:5px 18px 5px 0;}
    }
  }
  @media(max-width:576px){
    .searchBg{max-width: 100%;}
    .lftSec{flex-wrap: wrap;
      .searchBg{margin-bottom: 10px;}
      .switchBg{margin: 0;}
    }
  }
}
.filterListed{display: flex;margin-bottom:10px;
  span{display: inline-flex;align-items: center;justify-content: center;font-size:11px;color:#0D6856;
    font-family: 'Avenir LT Std 65';height:30px;border-radius:15px;padding:3px 15px;
    background:rgba(255,255,255,0.59);margin:0 10px 0 0;
    &:last-child{margin-right: 0;}
    .closeCat{cursor: pointer;margin-left: 12px;background: none;}
  }
}
.searchBg{
  max-width: 300px;width: 100%;
  .input-group{
  .form-control{border: none!important;border-radius: 30px 0 0 30px;padding-right: 0!important;padding-left: 25px;}
  .btn{background: #fff;border-radius: 0 30px 30px 0;padding: 0;width: 40px;}
  }
  .form-control::-webkit-input-placeholder{color: rgba(97, 97, 97, .6);}
  .form-control::-moz-placeholder{color: rgba(97, 97, 97, .6);}
  .form-control:-ms-input-placeholder{color: rgba(97, 97, 97, .6);}
  .form-control:-moz-placeholder{color: rgba(97, 97, 97, .6);}
  .form-control:focus::-webkit-input-placeholder{color: rgba(97, 97, 97, .6);}

  &.search-borderd{border: 1px solid #CED4D8;border-radius: 6px;max-width:100%;
    .form-control{height: 40px;padding-left: 15px;}
  }
}

.navTab-Bg{display: flex;justify-content: space-between;align-items:center;margin-bottom:15px;position: relative;
  .nav-tabs{border-color: transparent;/*height: 24px;*/
    .nav-link{color: $primary;font-size: 12px;text-transform: uppercase;margin:0 22px;line-height: normal;
      letter-spacing: 1px;font-family: 'Avenir LT Std 65';padding:0 0 12px;
      &:hover,&.active{color: $secondary;border-color:transparent;border-bottom-color: $secondary;
        background: none;}

      @media(max-width:1200px){margin: 0 10px;}
    }
  }
  &.show:before{content: '';background:rgba(0,0,0,.22);
    width: 100%;height: 100%;top: 0;left: 0;position: fixed;z-index: 99;}
}

.filtrBg{position: inherit;display:inline-flex;align-items:center;margin-right:25px;
  .filtrIco{cursor: pointer;display: inline-flex;position: relative;
    >span{background: #DC5D21;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      position: absolute;
      right: -6px;
      top: -5px;}
  }
  .filtrContBg{width: 100%;left: auto !important;right: 0 !important;transform: translate(0px, 0px)!important;
    border-radius: 18px;box-shadow:0 1px 2px rgba(0,0,0,.16);padding:30px 0 0;
    transition: all 150ms linear;
    visibility: hidden;
    opacity: 0;
    height: 0;
    display: block;
    &.show{transform: translate(0px, 10px)!important;height: auto;opacity: 1;visibility: visible;}
    .filtrContHead{padding: 0 30px 25px;
      h4{margin: 0;color: $default;font-size: 17px;margin-bottom: 13px;font-family: 'Avenir LT Std 65';
        position: relative;
        .restBtn{position: absolute;right: -13px;top:-15px}
      }
      .filtrLabel{display: flex;align-items:center;flex-wrap:wrap;
        span{height: 40px;display: inline-flex;align-items: center;justify-content: center;padding: 4px 20px;
          color: #2D2D2D;font-size: 13px;margin:5px 10px 5px 0;border-radius:30px;border:1px solid #C9C9C9;
          transition: all ease-in-out .3s;background:#fff;cursor: pointer;
          &:last-child{margin: 0;}
          &.active{background: #7AB648;border-color: #7AB648;color: #fff;}
        }
      }
      @media(max-width:767px){padding: 0 20px 15px;
        h4 .restBtn{right: -5px;}
        .filtrLabel span{height: 35px;}
      }
    }
  }
  .FilCollapseBox{height: 0;padding: 0 30px;opacity: 0;
    border-top: 1px solid #E1E1E1;transition: all ease-in-out .3s;
    .inBox{padding:0;transition: all ease-in-out .3s;display:flex;width:100%;
      .form-group{margin: 0 20px 20px 0;max-width: 285px;width:100%;position:relative;
        label{color: $default;font-size: 16px;}
      }
    }
    &.show{height: auto;opacity: 1;
      .inBox{padding:30px 0 0;}
    }
    .FilCollapseFtr{display: flex;justify-content: flex-end;padding-bottom: 35px;
      .cancelBtn{margin-left: 20px;}
    }
  }
}
.dateTimeBg{display: flex;align-items: center;position: relative;
  img{position: absolute;right: 10px;top: 0;bottom: 0;margin:auto;pointer-events: none;}
}

.SortBg{display: inline-flex;align-items:center;margin-right: 18px;
  >a{color: #616161;display: inline-flex;position: relative;font-size:14px;
    >span{background: #DC5D21;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      position: absolute;
      right: -6px;
      top: -5px;}
  }
  .SortContBg{padding: 0 18px;padding-top: 18px;min-width: 225px;border-radius: 18px;border: 1px solid #E6EAEE;
    box-shadow: 3px 4px 10px rgba(0,0,0,.08);
    &.show{max-height: none;}
    .sortCont{max-height:300px;overflow:auto;
      li{color: #646565;margin-bottom: 15px;
        &:last-child{margin-bottom: 0;}
        label{font-size: 13px;}
      }
    }
    .sortFtr{padding: 15px 0;
      .common-btn{height: 30px;background: #19967D;font-size: 12px;padding: 2px 15px;}
    }
    &.show{transform: translate(0px, 16px)!important;}
  }
}

.listCount{display: inline-flex;align-items: center;
  >span{color: #4F4F4F;font-size: 14px;margin-right: 6px;}
  .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn){min-width: 65px;}
  .btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active, .show > .btn-light.dropdown-toggle{background: #fff;color: #313131;}
  .bootstrap-select{
    .btn{background: #fff;height: 30px;padding: 2px 15px;color: #313131;border: none;}
    >.dropdown-menu{min-width: 100% !important;border-radius: 18px;padding: 15px 10px;
      border: 1px solid #E6EAEE;box-shadow: 3px 4px 10px rgb(0 0 0 / 8%);
      &.show{margin-top: 0!important;}
    }
  }
}

.addNew-Wrapper{position: fixed;width: 100%;height: 100%;background: rgba(0,0,0,.22);
  left: 0;top: 0;transition: all ease-in-out 0.3s;z-index: 99;visibility: hidden;opacity: 1;
  display: flex;justify-content:flex-end;
  .addNewCont{width: 100%;max-width: 650px;height:100%;position: relative;right: -100%;
    background: #fff;box-shadow: 0 1px 2px rgba(0,0,0,0.16);transition: all ease-in-out 0.3s;
    padding: 70px 35px;
    .addNewHead{display: flex;align-items: baseline;
      span{top: -2px;
        img{margin-right: 20px;cursor: pointer;}
      }
      h1{margin: 0;font-family: 'Avenir LT Std 65';color: $default;font-size: 17px;line-height: normal;}
    }
    .formBg{margin: 35px 0 0;height: calc(100% - 55px);overflow: auto;
      textarea.form-control{height: 85px;}
      .form-group{margin-bottom: 15px;
        label{justify-content: flex-start;margin-bottom:0;
          span{color: #787878;font-size: 12px;margin-left: 2px;}
        }
        >input,.mat-mdc-form-field{margin-top:8px;}
        p{color: #787878;font-size: 15px;}
      }
      .addNewFtr{display: flex;justify-content: flex-start;
        .cancelBtn{margin-left: 20px;}
      }
    }
    .bootstrap-select .dropdown-menu{min-width: 100%!important;}
    .bootstrap-select > .dropdown-menu{border: 1px solid #CED4D8!important;border-radius: 6px;
      li>a{display: flex;align-items: center;font-size: 14px;padding:3px 15px;
        .fa{font-size: 9px;}
          &.pending{
            .fa{color: #ffc107;}
          }
          &.completed{
            .fa{color: #7AB648;}
          }
      }
      .bs-searchbox{position: relative;
        input{padding-right: 30px;}
        &:before{content: "\e914";font-family: 'icomoon';font-size: 14px;
          position: absolute;top: 16px;right: 20px;}
      }
    }
    @media(max-width:1400px){padding: 40px 35px;}
  }
  &.active{visibility: visible;opacity: 1;
    .addNewCont{right: 0;}
  }
}

.cstmModal,.mat-mdc-dialog-container{
  .modal-content{background: transparent;}
  .cstmModalBody{margin: auto;position: relative;padding: 0;width:100%;min-width:auto;
    .cstmrHeading{padding: 15px 30px;border-bottom: 1px solid #E1E1E1;
      h1{font-size: 17px;margin:0;font-family: 'Avenir LT Std 65';}
    }
    .cstmModalCont{padding:25px 30px;}
  }
}

.usrTd{display: flex;align-items: center;
  span{padding: 0 5px;}
}
.D-addrs{display: flex;align-items: center;
  span.lockSec{position: relative;width:26px;height:26px;border-radius:50%;cursor: pointer;
    display:inline-flex;align-items:center;justify-content:center;overflow:hidden;
    img{position: absolute;z-index: 2;top: 0;bottom: 0;left: 0;right: 0;margin: auto;
      transition: all ease-in-out .3s;transform: scale(1);opacity: 1;
      &.unlock{transform: scale(0);}
    }
    &:before{content: '';position: absolute;top: 0;left: 0;bottom: 0;right: 0;transition: all ease-in-out .4s;
    background: #D6E4E3;border-radius: 13px;transform: scale(0);}
    &:hover:before{transform: scale(1);}
    &.active{
      img{
        &.lock{transform: scale(0);}
        &.unlock{transform: scale(1);}
      }
    }
  }
  a{color: #4F4F4F;margin-left: 5px;}
}

td{
  .bootstrap-select{
    .btn{height: 30px;padding: 3px 12px;border-radius: 6px;}
    .dropdown-menu{display: none;
      &.show{display: block;}
    }
  }
}

.pos-relative{position: relative;
  .form-control,input.mat-mdc-input-element{padding-right: 81px;}
  .checkbox,.mat-mdc-checkbox{position: absolute;top: 11px;right: 18px;width: auto;
    label{color: #6C7174;font-size: 13px;line-height: 23px;}
  }
}

.AddnewBox{width: 100%;max-width: 730px;
  h3{color: #0D6856;font-size: 19px;margin: 0 0 30px;font-family: 'Avenir LT Std 65';}
}

.btnBg{margin-top: 30px;display:flex;align-items:center;
  .common-btn, .cancelBtn{min-width: auto;}
  .common-btn{background: #19967D;}
  .cancelBtn{color: #0D6856;background: #D6E4E3;margin-left: 20px;}
}
.statusRound{display: flex;align-items: center;position: relative;
  span{width: 18px;height: 18px;border-radius: 50%;border:1px solid transparent;color: #fff!important;
    display: inline-flex;align-items: center;justify-content: center;font-size:11px;text-transform:uppercase;
    &.green{color:$green!important;border-color:$green;}
    &.orange{background:$orange1!important;}
    &.blue{background:$blue!important;}
  }
}

.findBg{height: 250px;display: flex;align-items: center;justify-content: center;
  .findCont{text-align: center;
    i{font-size: 24px;color: #5AB475;}
    p{color: $default;font-size: 17px;font-family: 'Avenir LT Std 65';margin-top: 15px;}
  }
}

@media(min-width:1201px){
  .p-m-40{padding: 40px!important;}
}


.table-filter{
  .form-control{border-right: 0!important;border-radius: 6px 0 0 6px;}
  .btn{background: #fff;border-radius: 0 6px 6px 0;border: 1px solid #CED4D8;border-left: none;padding-left: 5px}
}




.mat-body p, .mat-body-2 p, .mat-typography p{margin: 0;}
/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-select-trigger{vertical-align: middle;
  /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .mat-select-arrow{display: inline-flex;}
}
.mat-mdc-form-field{width: 100%;}
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-outline .mat-form-field-wrapper{width: 100%;margin: 0!important;padding-bottom: 0;}
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-outline .mat-form-field-outline,
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick,
.mat-form-field-appearance-outline .mat-form-field-outline-thick{color: #CED4D8;}
.mat-body, .mat-body-2, .mat-typography{font:inherit;}

.table-loder{width: 100%;
  tr>td{text-align: center;padding: 50px 20px;
    img{max-width: 60px;}
  }
}


.tableFilter{
  th.mat-sort-header{min-width:125px;}
  .mat-sort-header-container{align-items: baseline;justify-content: space-between;position: relative;
    .mat-sort-header-arrow{position: absolute;right: 0;top:2px;color: #B5B5B5;}
  }
  .mat-sort-header-content{flex-direction: column;align-items: flex-start;width:100%;
    .filtrRow1{display: flex;justify-content: space-between;width: 100%;
      a{padding: 0 4px;margin-right: 15px;margin-left: 5px;}
    }
  }
  .tbl-filtr{position: relative;width:100%;transition:all .3s ease-in-out;height:0;overflow:hidden;
    input{text-align: left;box-sizing: border-box;background: #fff;height: auto;min-width: 90px;padding: 6px 20px 7px 10px!important;}
    .mat-mdc-icon-button{width: auto;height: auto;line-height: normal;display: inline-flex;align-items: center;position: absolute;
      top: 0;right: 0;bottom: 0;margin:auto;padding-right: 5px;
      .mat-icon{font-size: 18px;line-height: normal;width: auto;height: auto;position: relative;top:3px;}
    }
  }
}
li{
  &.dragBox{background-color: #fff!important;border-radius: 6px;border: 1px solid #E9E9E9;padding: 15px 25px;
    h6{color: #313131;font-size: 16px;font-family: "Avenir LT Std 65";margin-bottom: 6px}
    p{display: flex;align-items: center;justify-content: space-between;color: #4F4F4F;font-size: 13px;
      i{font-size: 16px;}
    }
  }
}

/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background{
  background-color: #7AB648;border-radius: 4px;}
/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-layout .mat-checkbox-label{color: #6C7174!important;font-family: "Avenir LT Std 65";}
/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-frame{background: #eee!important;border: 1px solid #E0E2E6!important;border-radius: 4px!important;}
.mat-ripple-element{display: none !important;}
/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-mdc-checkbox .mat-checkbox-layout{margin-bottom: 0;}

/*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
.mat-slide-toggle-bar{background: #CFCFCF;width: 30px!important;}
/*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
.mat-slide-toggle-thumb-container{width: 15px!important;height: 15px!important;top:0px!important;
  /*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
  .mat-slide-toggle-thumb{width: 14px;height: 14px;box-shadow: none;border: 1px solid #606060;background: #fff;}
}
.mat-mdc-slide-toggle.mat-checked {
  /*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
  .mat-slide-toggle-bar{background: #29BEA0;}
  /*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
  .mat-slide-toggle-thumb{background: #fff;border:1px solid #29BEA0;}
}
/*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-radio-container{width: 16px!important;height: 16px!important;
  /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  .mat-radio-outer-circle{border:1px solid #C2CCD8!important;height: 16px!important;width: 16px!important;}
}
/*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-mdc-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{border-color:#C2CCD8!important;}
.mat-mdc-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple{background-color: #007ae8;}
/*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-radio-inner-circle{width: 16px!important;height: 16px!important;}

.cdk-overlay-dark-backdrop{background: rgba(0,0,0,.5);}
.cdk-global-overlay-wrapper>.cdk-overlay-pane{width: 100%!important;}
.mat-mdc-dialog-container{border-radius: 24px!important;padding: 0!important;}

ngx-avatars{margin: 0 3px;
  .avatar-container{
    .avatar-content{width: 35px;height: 35px;}
  }
}
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-form-field.mat-focused .mat-form-field-label{color: #19967D;}
.text-danger{top: 3px;font-size: 13px;}
body.modal-open{height:auto;}
.cdk-overlay-container{z-index: 9999;}
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-outline .mat-form-field-suffix{top: .45em!important;}
/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-focus-indicator .mat-button-wrapper{color: rgba(0,0,0,.54);}

.sorthead {
  margin-bottom: 15px;
  button {
    padding: 5px 10px;
    height: 32px;
    font-size: 12px;
  }
  .cancel {
    font-size: 12px;
    background-color: rgba(13, 104, 86, 0.1);
    border-radius: 6px;
    padding: 5px 10px;
    height: 32px;
    margin-left: 8px;
  }
}
/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-mdc-checkbox span.mat-checkbox-label {
  color: #313131 !important;
}
.sortCont [type=checkbox] {
  margin-right: 10px;
}
