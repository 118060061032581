@font-face {
  font-family: 'icomoon';
  src:  url('../icons/icomoon.eot?69nz0a');
  src:  url('../icons/icomoon.eot?69nz0a#iefix') format('embedded-opentype'),
    url('../icons/icomoon.ttf?69nz0a') format('truetype'),
    url('../icons/icomoon.woff?69nz0a') format('woff'),
    url('../icons/icomoon.svg?69nz0a#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-menu9:before {
  content: "\e900";
  color: #6c7174;
}
.icon-filter:before {
  content: "\e905";
  color: #616161;
}
.icon-menu1:before {
  content: "\e906";
  color: #6c7174;
}
.icon-menu2:before {
  content: "\e907";
  color: #6c7174;
}
.icon-menu3:before {
  content: "\e908";
  color: #6c7174;
}
.icon-menu4:before {
  content: "\e909";
  color: #6c7174;
}
.icon-menu5:before {
  content: "\e90a";
  color: #6c7174;
}
.icon-menu6:before {
  content: "\e90b";
  color: #6c7174;
}
.icon-menu7:before {
  content: "\e90c";
  color: #6c7174;
}
.icon-menu8:before {
  content: "\e90d";
  color: #6c7174;
}
.icon-more:before {
  content: "\e90e";
  color: #6c7174;
}
.icon-noti:before {
  content: "\e90f";
  color: #616161;
}
.icon-prgrmIcon1:before {
  content: "\e910";
  color: #0d6856;
}
.icon-prgrmIcon2:before {
  content: "\e911";
  color: #be12d1;
}
.icon-prgrmIcon3:before {
  content: "\e912";
  color: #339503;
}
.icon-prgrmIcon4:before {
  content: "\e913";
  color: #0d6856;
}
.icon-search:before {
  content: "\e914";
  color: #616161;
}
.icon-settings:before {
  content: "\e915";
  color: #616161;
}
.icon-sort:before {
  content: "\e916";
  color: #616161;
}
.icon-menu6a:before{
  content: "\e916";
  color: #616161;
}
.icon-menu6b:before{
  content: "\e916";
  color: #616161;
}
